 .dept_main {
  width: calc(20% - 20px);
  margin-bottom: 20px; 
  font-size: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: visible; 
}

.img_cards {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  transition: opacity 0.3s, border 0.3s;
  box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.55), 15px 0px 10px -10px rgba(0, 0, 0, 0.55);
}

.images {
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 5px 10px -5px rgba(66, 68, 90, 1.5);
  border-radius: 30px;
}

.card_link {
  color: inherit;
  text-decoration: none;
}

.img_cards.disabled {
  opacity: 0.5;
}


.dept_home {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px; 
}

.img_cards:hover {
  transform: scale(0.92);
}

@media (max-width: 1200px) {
  .dept_main {
    width: calc(25% - 20px);
  }
}

@media (max-width: 992px) {
  .dept_main {
    width: calc(33.33% - 20px);
  }
}

@media (max-width: 768px) {
  .dept_main {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .dept_main {
    width: calc(100% - 20px);
    font-size: 24px;
  }

  .images {
    height: auto;
  }
}






 










/* .dept_main {
  width: calc(20% - 20px);
  margin-bottom: 5px;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
}

.img_cards {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  transition: opacity 0.3s, border 0.3s;
  box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.55), 15px 0px 10px -10px rgba(0, 0, 0, 0.55);
}



.images {
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 5px 10px -5px rgba(66, 68, 90, 1.5);
  border-radius: 30px;
}

.card_link {
  color: inherit;
  text-decoration: none;
}

.img_cards.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dept_home {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.img_cards:hover {
  transform: scale(0.92);
}


@media (max-width: 1200px) {
  .dept_main {
    width: calc(25% - 20px);
  }
}

@media (max-width: 992px) {
  .dept_main {
    width: calc(33.33% - 20px);
  }
}

@media (max-width: 768px) {
  .dept_main {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .dept_main {
    width: calc(100% - 20px);
    font-size: 24px;
  }

  .images {
    height: auto;
  }
} */