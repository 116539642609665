@import url('https://fonts.googleapis.com/css2?family=Danfo&display=swap');

.container {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.login {
  width: 400px;
  height: 500px;
  color: black !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 70px 30px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 27px 0px rgba(12,12,13,0.55);
  -moz-box-shadow: 0px 0px 27px 0px rgba(12,12,13,0.55);
  box-shadow: 0px 0px 27px 0px rgba(12,12,13,0.55);
}

.login h2 {
  margin: 0;
  padding: 0 0 20px;
  text-align: center;
  font-size: 22px;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.inputBox {
  width: 100%;
  margin-bottom: 20px;
}

.inputBox input {
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 16px;
  padding: 10px 0;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.inputBox input:focus {
  border-bottom: 1px solid black;
}

.inputBox input::placeholder {
  color: black;
  font-family: "Teko", sans-serif;
}

.links {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.links a {
  text-decoration: none;
  color: black;
  font-family: "Playfair Display", serif;
}

.links a:hover {
  text-decoration: underline;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buttons button,
.buttons .microsoft-button {
  border: none;
  outline: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
}

.buttons button {
  background-color: rgba(16, 3, 255, 0.802) !important;
  font-family: "Playfair Display", serif;
  letter-spacing: 2px;
}

.buttons button:hover {
  background-color: white !important;
  color: blue !important;
  border: 1px solid blue;
}

.buttons button:hover a {
  color: blue !important;
}

.buttons .microsoft-button {
  padding: 10px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  box-shadow: rgb(4, 11, 220) -2px -2px 0px 2px, rgb(0, 0, 0) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 0px 2px 7px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; /* Ensure no underline */
}

.buttons .microsoft-button img {
  width: 20px;
  margin-right: 10px;
}

.buttons .microsoft-button:hover {
  background-color: white !important;
  color: blue !important;
  border: 1px solid blue;
  box-shadow: black -2px -2px 0px 2px, rgb(4, 11, 220) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 0px 2px 7px;
  transform: scale(1.01);
}

.buttons .microsoft-button a {
  text-decoration: none; /* Ensure no underline for links inside the button */
  color: inherit; /* Ensure the link takes the color of its parent */
}

.or-line {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.or-line hr {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #000;
}

.or-line span {
  margin: 0 10px;
  font-family: "Playfair Display", serif;
  font-size: 14px;
  color: #000;
}

@media screen and (max-width: 480px) {
  .login {
    width: 90%;
    padding: 40px 20px;
  }

  .inputBox input {
    font-size: 14px;
    padding: 8px 0;
  }

  .buttons button,
  .buttons .microsoft-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .buttons .microsoft-button img {
    width: 18px;
    margin-right: 8px;
  }
}
