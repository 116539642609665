





.desc {
  color: #333;
  margin-bottom: 20px;
  line-height: 25px;
}

.cardButton {
  border: none;
  border-radius: 10px;
  background-color: rgba(53, 16, 102, 0.678);
  padding: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}


.google{
  background-color: rgba(0, 1, 13, 0.86);
  height: 40px;
}
.facebook{
  background-color: #507cc0;
}
.github{
  background-color: black;
}

.icon{
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

input{
  width: 200px;
  padding: 15px 20px;
  margin-bottom: 20px;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}
.left, .right {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 550px;
}
form {
  max-width: 500px; /* Limit form width */
  margin: 0 auto; /* Center the form horizontally */
  
}

form div {
  margin-bottom: 15px; /* Increase spacing between form elements */
  display: flex; /* Use Flexbox to align items */
  align-items: center; /* Center items vertically */
}

form label {
  margin-bottom: 0; /* Remove bottom margin */
  margin-right: 10px; /* Space between label and input */
  font-weight: bold;
  color: #555; /* Update label color */
  flex: 0 0 120px; /* Fixed width for the label */
}

form input[type="text"],
form textarea {
  width: calc(100% - 130px); /* Adjust width to account for label */
  padding: 12px; /* Increase input padding */
  border: 1px solid #ccc;
  border-radius: 8px; /* Round input corners */
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease; /* Add transition for input border */
}
form select {
  width: 100%;
  padding: 12px; /* Increase input padding */
  border: 1px solid #ccc;
  border-radius: 8px; /* Round input corners */
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease; /* Add transition for input border */
}

/* Add focus styles */
form input[type="text"]:focus,
form textarea:focus {
  border-color: #007bff; /* Update border color on focus */
  outline: none;
}

form textarea {
  min-height: 80px; /* Set minimum height for textarea */
  width: calc(100% - 130px); /* Adjust width to account for label */
}

form button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 14px 24px; /* Increase button padding */
  border-radius: 8px; /* Round button corners */
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.3s ease; /* Add transition for button background */
}

/* Add hover effect */
form button:hover {
  background-color: #0056b3;
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table, th, td {
  border: 1px solid #dddddd;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #007BFF;
  color: #ffffff;
}

td {
  background-color: #f9f9f9;
}

 .proceedButton {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
} 

.proceedButton:hover {
  background-color: #218838;
}
.right img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: 60%; 
  border-radius: 10px; 
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);  */
}
.proceedButton {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  width: 50%;
  position: relative; /* Ensure proper positioning for the progress bar */
}





@media screen and (max-width: 992px) {
  .card {
    width: 100%;
    margin-bottom: 50px;
  }

  .wrapper {
    width: 65%;
    height: 90%;
    flex-direction: column;
  }

  .loginTitle {
    display: none;
  }

  .center {
    width: 100%;
    position: relative;
  }

  .left {
    padding: 20px;
  }

  .right {
    padding: 20px;
  }

  .line {
    height: 0.5px;
    width: 300px;
  }
}
