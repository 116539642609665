@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.navbar {
  height: 50px;
  background-color: rgba(3, 11, 247, 0.849);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.link {
  color: inherit;
  text-decoration: none;
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  color: blue;
  display: flex;
  align-items: center;
}

.listItem .listItem-name,
.listItem .listItem-img {
  background-color: white;
  color: white; 
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px; 
}

.logout-item {
  display: flex;
  align-items: center;
  color: blue;
}

.logout-text,
.logout-icon {
  background-color: white;
  color: blue;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
}

.logout-icon {
  display: none;
  font-size: 18px;
}

.listItem-home, .listItem-name {
  margin-right: 25px; 
  color: white;
}
.listItem-logout {
  margin-right: 25px; 
  color: blue;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .listItem {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .listItem .listItem-name,
  .listItem .listItem-img {
    width: 100%;
    text-align: left;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .list {
    flex-direction: row;
    justify-content: space-between; 
    width: 100%;
  }

  .listItem {
    margin-right: 0;
    flex: 1; 
    text-align: center; 
  }

  .listItem-home {
    flex: 1; 
    color: white;
  }

  .avatar {
    width: 28px;
    height: 28px;
  }

  .listItem-name {
    color: white;
    margin-right: 0; 
    flex: 1; 
  }

  .logout-text {
    display: block;
  }

  .logout-icon {
    display: none;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px;
    flex-direction: column;
    height: auto;
  }

  .logo {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .list {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .listItem {
    font-size: 14px;
    margin-right: 10px;
    flex: 1;
    text-align: center;
  }

  .listItem-home,
  .listItem-name,
  .listItem-logout {
    margin-right: 10px;
    flex: 1;
    text-align: center;
  }

  .avatar {
    width: 28px;
    height: 28px;
  }

  .logout-text {
    display: none;
  }

  .logout-icon {
    display: block;
    color: black;
  }
}
























