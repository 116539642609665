

.modal-content {
    background-color: #f8f9fa; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.modal-header {
    border-bottom: none; 
}

.modal-header .close {
    font-size: 1.5rem; 
}

.oops {
    font-size: 30px;
    font-weight: bold;
    color: #dc3545; 
}

.contact {
    color: #007bff; 
}
.contact:hover {
    text-decoration: underline;
    color: blue;
}

.modal-body {
    font-size: 1rem;
    line-height: 1.5;
    color: #495057; 
}

.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center; 
}

.modal-footer .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.modal-footer .btn-secondary:hover {
    background-color: #5a6268; 
}



