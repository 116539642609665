

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.blankpage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blankpage-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  position: absolute;
  top: 0;
  left: 0;
}

.back-to-home {
  position: absolute;
  left: 50%;
 margin-top: 50px;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; 
}

.back-to-home:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .back-to-home {
    font-size: 14px; 
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .back-to-home {
    font-size: 12px; 
    padding: 6px 12px;
  }
}


















