.dept_home {
    display: flex;
    padding: 50px 100px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px; 
    max-height: calc(100vh - 100px); 
    overflow-y: auto;
  }
  
  h2 {
    margin-top: 25px;
    color: blue;
    text-align: start;
    margin-left: 5%;
  }
  
  