.home {
    display: flex;
    padding: 50px 100px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px; 
    max-height: calc(100vh - 150px); 
    overflow-y: auto; 
}

.icon {
    height: 50px;
    width: 50px;
    color: #007BFF;
    margin-top: 30px;
    margin-left: 50px;
}


