
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-x: auto; 
  padding-bottom: 20px; 
}

.card {
  width: calc(30% - 30px);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img {
  margin-bottom: 5px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 5px 10px -5px rgba(66, 68, 90, 1);
}

.link {
  color: inherit;
  text-decoration: none;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.small-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
}

.title {
  font-size: 1.2em;
  font-weight: bold;
  flex: 1;
  text-align: left;
}

.card:hover {
  transform: scale(0.98);
  opacity: 0.6;
  filter: grayscale(60%);
}

@media (max-width: 768px) {
  .card-container {
    justify-content: flex-start; 
    padding-left: 10px; 
  }

  .card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 760px) and (min-width: 460px) {
  .card-container {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .card {
    width: calc(100% - 20px);
  }
}

@media (max-width: 480px) {
  .card-container {
    justify-content: center; 
    padding-left: 0; 
  }

  .card {
    width: 90%; 
  }
}




















